.listing-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 140px;
  height: 100%;
}

.listing-detail h1 {
  margin-bottom: 2rem;
  font-size: 2.2rem;
  text-align: center;
}

.content-container {
  display: flex;
  width: 100%;
  max-width: 1200px;
  flex-direction: row;
  align-items: stretch;
  margin-top: 2rem;
}

.left-column,
.right-column {
  flex: 1;
  /* This will ensure that both columns take up equal space */
  padding: 1.5rem;

}

.right-column {
  max-height: 600px;
}

.ai-description-box {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 8px;
  background: #f9f9f9;
  margin-top: 20px;
}

.address-box,
.property-details-box,
.transaction-box,
.description-box {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 8px;
  background: #f9f9f9;
  margin-bottom: 20px;
}

h2 {
  margin-top: 0;
  color: #333;
  font-size: 1.25em;
}

p {
  margin: 5px 0;
  color: #666;
}

@media (min-width: 768px) {
  .content-container {
    flex-direction: row;
  }

  .left-column,
  .right-column {
    flex-basis: 50%;
  }

  .ai-description-box {
    margin-left: 20px;
  }
}

@media (max-width: 767px) {
  .content-container {
    flex-direction: column;
  }

  .ai-description-box {
    margin-top: 20px;
  }
}

/* Style for the copy button */
.copyButton {
  padding: 8px 16px;
  margin-top: 0px; /* Spacing from the text */
  font-size: 1rem;
  color: #fff;
  background-color: #007bff; /* Example color: Bootstrap primary button color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s;
}
.copyButton:hover {
  background-color: #0056b3; /* Darker shade for hover effect */
  transform: scale(1.02);
}



.ai-description-box button:hover {
  background-color: #0056b3; /* Darker shade for hover effect */
}


.form-step-container2 {
  max-width: 600px;
  width: 100%;
  background: #f4f4f4;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
}

.ai-container {
  max-width: 600px;
  height: 480px; /* or any specific height you want */
  width: 100%;
  background: #f4f4f4;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevents any content from spilling outside */
}

.input-field-display2 {
  background: #fff;
  border: 1px solid #CCCCCC;
  border-radius: 25px;
  padding: 15px 20px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.input-label2 {
  font-weight: bold;
  text-align: left;
}

.form-header-container2 {
  height: 45px; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  /* margin-bottom: 50px; */
}
.form-content-container {
  margin-top: 30px;
}

.ai-form-content-container {
  margin-top: 30px;
  max-height: 300px;
}
.textarea-display {
  /* Make this take up all available space not taken by the button */
  flex-grow: 1;
  overflow-y: auto; /* Adds a scrollbar if content overflows */
  margin-bottom: 10px; /* Adjust or remove this as necessary */
}
.ai-textarea-display {
  /* Make this take up all available space not taken by the button */
  flex-grow: 1;
  overflow-y: auto; /* Adds a scrollbar if content overflows */
  margin-top: 30px;
  margin-bottom: 30px; /* Adjust or remove this as necessary */
  height: 60%;
}

.form-title2 {
  font-size: 26px;
  color: #333;
  margin: 0;
}

.textarea-field-display {
  width: 100%; /* Full width of the container */
  height: 20em; /* Provides more vertical space */
  padding: 15px; /* Padding inside the text area */
  border-radius: 10px; /* Rounded corners */
  border: 1px solid #ccc; /* Light border */
  font-size: 16px; /* Readable text size */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin-bottom: 36px; /* Provides space between the text area and the button */
  /* Add any other styles such as margin or background-color as needed */
}

.address-details2 {
  display: none; /* Start as not visible */
  /* When the address details are visible, the 'display' property will be set to 'block' by React */
}

.toggle-button {
  width: 40px; /* Size of the button */
  height: 40px; /* Size of the button */
  border-radius: 50%; /* Makes it circular */
  border: none; /* Removes border */
  background-size: cover; /* Ensures the image covers the button */
  cursor: pointer;
  outline: none; /* Removes the outline to maintain the shape */
  background-color: #ccc;
  transition: transform 0.3s ease, background-color 0.3s;
}

.toggle-button img {
  width: 40px; /* Size of the button */
  height: 40px
}
.toggle-button:hover {
  transform: scale(1.15);
}
