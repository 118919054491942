.form-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Aligns children (cancel button container) to the start */
  width: 600px;
}

.cancel-button-container {
  width: 100%;
  /* Ensures the container stretches across the full width */
  display: flex;
  /* Enables flexbox for the container */
  justify-content: flex-start;
  /* Aligns the cancel button to the start */
  margin-bottom: 10px;
  /* Adds some space below the cancel button */
}

.cancel-button {
  display: flex;
  align-items: center;
  /* Vertically centers the icon with the text */
  justify-content: flex-start;
  /* Aligns the button contents to the left */
  gap: 10px;
  /* Adds some space between the icon and the text */

  background: transparent;
  border: 0px solid #ccc;
  padding: 10px 0px;
  cursor: pointer;
  color: rgb(52, 120, 246);
  font-weight: bold;
  text-align: left;
}

.button-icon {
  width: 16px;
  height: 16px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* or absolute, depending on your layout */
  top: 80px; /* height of your header */
  left: 0;
  right: 0;
  bottom: 80px;
  z-index: 1000; /* Ensure it's above other content except the header */
  flex-direction: column;
}

.inner-loading-container {
  background-color: #e4ded1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  flex-direction: column;
  padding: 15px 40px;
  border-radius: 12px;
}

.spinner {
  border: 5px solid #F4EEE0; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin-bottom: 10px;
}

.statusMessage {
  font-weight: bold;
}

.progress-bar-container {
  width: 600px;
  /* Full width of the container */
  background-color: #e0e0e0;
  /* Light grey background for the unfilled part of the progress bar */
  border-radius: 20px;
  /* Rounded corners for the progress bar container */
  margin-top: 0px;
  /* Space below the progress bar */
}

.progress-bar {
  height: 10px;
  /* Height of the filled part of the progress bar */
  background-color: #000000;
  /* Use a color that matches your site's theme */
  border-radius: 20px;
  /* Rounded corners for the filled part of the progress bar */
  transition: width 0.3s ease-in-out;
  /* Smooth transition for the progress bar animation */
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}