.map-container {
    height: 180px;
    /* Adjust size as needed */
    width: 180px;
    border-radius: 50%;
}

.mapboxgl-marker {
    transform: scale(0.5);
    /* Scale down by 50% */
}