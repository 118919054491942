
  .contact-us {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align text to the start */
    justify-content: flex-start;
    padding: 5% 10%; /* Responsive padding */
    padding-top: 140px;
    max-width: 800px; /* Max width for readability */
    margin: auto; /* Center the container */
    color: #333; /* Dark text for contrast */
    font-family: 'Open Sans', sans-serif; /* Legible font */
}

h1 {
    color: #2a2a2a; /* Slightly darker color for headings */
    margin-bottom: 0.5em;
}

.contact-headings {
    color: #5a5a5a; /* Subtle color for subheadings */
    margin-top: 2em; /* Space out sections */
}

p {
    line-height: 1.3; /* Improve readability */
    margin-bottom: 1em; /* Space after paragraphs */
    /* text-align: left; */
}
.contactText {
    text-align: left;
}

strong {
    color: #000000; /* Highlight important numbers with color */
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .FAQ {
        padding: 5%; /* More padding on smaller screens */
    }
}
