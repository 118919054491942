.subscribe {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* Align content to the top */
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 140px;
    height: 100%;
    /* Ensure dashboard takes up full viewport height */
  }