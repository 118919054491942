.landing-page {
  text-align: center;
  /* padding: 6rem 0rem; */
  padding-top: 176px;
}

@keyframes gradient-shift {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 75% 50%;
    /* Move the background by three times the size to loop */
  }
}

.gradient-text {
  background: linear-gradient(to left, #FC5C7D, red, rgb(216, 140, 0), blue, green, rgb(255, 118, 140), red, #6A82FB, #FC5C7D);
  /* End color red matches the start */
  background-size: 1000% 100%;
  /* Set a size that includes all colors plus one extra to complete the loop */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
  animation: gradient-shift 30s linear infinite;
  /* Adjust the time as needed */
}

/* For browsers that do not support background-clip: text */
.no-background-clip .gradient-text {
  background: none;
  color: black;
  /* Fallback color */
}

.hero {
  max-width: 900px;
  margin: auto;
  margin-bottom: 5rem; /*6 rem*/
}

.hero h1 {
  font-size: 3.2rem;
  font-weight: 900;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 8rem;
}

.hero .one-free-label {
  font-size: 1.2rem;
  margin-bottom: 3rem;
}

.btn {
  border: 2px solid black;
  text-decoration: none;
  color: white;
  background-color: #000000;
  padding: 15px 40px;
  border-radius: 12px;
  display: inline-block;
  font-size: 15px;
  width: 250px;
  height: 54px;
}

.btn-primary:hover {
  background-color: transparent;
  color: black;
}

.start-listing-button {
  font-weight: 850;
}



.how-it-works {
  background-color: #e9e3d4;
  padding: 4rem 0;
  text-align: center;
  width: 100%;
}

h2 {
  margin-bottom: 2rem;
  color: #333;
  /* font-size: 10px; */

}

.steps-container {
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  /* Adjust based on your layout */
  margin: auto;
}

.step {
  flex: 1;
  /* This ensures each step takes up equal width */
  margin: 0rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #d8d2c4;
  border-radius: 25px;
  /* Aligns content of step in the center */
}

.step img {
  width: 180px;
  height: 180px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 90px;
}

.number {
  font-weight: 700;
  margin-top: 1.5rem;
  font-size: 3rem;
  color: black;
  text-align: center;
  /* background-color: #007bff; */
  margin-bottom: 1rem;
}

.step-description h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.step-description p {
  font-size: 1rem;
  font-weight: 500;
  color: #666;
  min-height: 100px;
  margin-left: 1.2rem;
  margin-right: 1.2rem;
}

.step-image {
  width: 10px;
  height: 10px;
}




.carousel-wrapper {
  padding: 2rem;
  background: transparent;
  max-width: 100%;
  /* Assuming the background is the light grey as seen in the screenshot */
}

.carousel-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  margin: 0 1rem; /* Adjust if this margin causes horizontal overflow */

}

.slide-content {
  display: grid;
  grid-template-columns: 1fr 3fr;
  /* Adjust the ratio according to the screenshot */
  gap: 2rem;
  /* Space between description and card */
  align-items: center;
  max-width: 800px;
  /* Or match to the max-width from the screenshot */
  margin: auto;
  /* Center the content */
}

.listing-description {
  text-align: left;
  /* Align text to the left as seen in the screenshot */
}

.listing-description h3,
.listing-description h2,
.listing-description p {
  margin: 0.5rem 0;
}

.listing-card {
  background: #d8d2c4;
  padding: 30px;
  /* Adjusted padding */
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  height: 300px;
  /* Height of the card */
  overflow: hidden;
  /* Hide the overflow to maintain border-radius */
  margin-bottom: 2rem;
  /* Space below the card */
  box-sizing: border-box;
  /* Include padding and border in the element's total width and height */
  position: relative;
  /* Context for absolute positioning */
  text-align: left;
  /* Align text to the left */
}

.listing-content {
  position: absolute;
  top: 30px;
  /* Align with the top of the card's padding */
  right: 30px;
  /* Align with the right of the card's padding */
  bottom: 30px;
  /* Align with the bottom of the card's padding */
  left: 30px;
  /* Align with the left of the card's padding */
  overflow-y: auto;
  /* Allow vertical scrolling */
  color: rgb(0, 0, 0);
  /* Text color */
  padding: 15px;
  /* Padding inside the content area */
  padding-top: 0px;
  border-radius: 4px;
  /* Border radius for the content area */
  overflow-y: scroll;
}

.listing-content p {
  margin: 0;
  /* Remove margins from paragraphs */
  padding: 0;
  /* Remove padding from paragraphs */
  line-height: 1.55;
  /* Line height for paragraphs */
  text-align: center;
  /* Align text to the left */
}

.slick-dots {
  text-align: center;
  /* Center align the dots */
}

/* Adjustments for slick-dots to match the screenshot */
.slick-dots li button:before {
  font-size: 12px;
  /* Size of the dots */
  color: rgba(0, 0, 0, 0.5);
  /* Color of the dots */
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  color: black;
  /* Color of the active dot */
}

.benefits {
  padding: 4rem 0;
  text-align: center;
}

.benefits-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Create two columns */
  gap: 30px;
  justify-content: center;
  align-items: start;
  /* Align grid items at the start of each column */
  margin: 0 auto;
  /* Center the grid horizontally */
  max-width: 700px;
  /* Set a max-width if needed to prevent cards from stretching too wide on larger screens */
}

.benefit-card {
  background-color: #e9e3d4;
  /* Card background color */
  border-radius: 5px;
  /* Rounded corners for the card */
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Example box shadow */
  text-align: center;
  /* height: 167px; */
}

.benefit-card h3 {
  margin-bottom: 15px;
  color: #333;
  /* Example text color */
  font-weight: 900;
}

.benefit-card p {
  color: #666;
  /* Example text color */
  font-size: 0.8em;
}


.example-listings {
  background-color: #e9e3d4;
  padding: 4rem 0;
  text-align: center;
}

.pricing {
  background-color: transparent;
  padding: 4rem 0;
  text-align: center;
}

.pricing-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  gap: 1rem;
  max-width: 1200px;
}

.plan {
  background: #f0ece4;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  /* Adjust width as needed */
  position: relative;
  min-height: 357px;
}

.plan h3 {
  color: #333;
  margin-bottom: 0rem;
}
.pricing-container .plan:last-child h3 {
  margin-bottom: 1.5rem; /* Increase space below the h3 */
}

.old-price {
  text-decoration: line-through;
  color: #777;
  margin-bottom: 20px;
}

.plan ul {
  list-style: none;
  padding: 0;
  text-align: left;
}

.plan ul li {
  margin-bottom: 0.5rem;
}

.plan button {
  position: absolute;
  background: #0044cc;
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  /* Centers the button */
  width: calc(100% - 4rem);
  /* Adjust the width of the button based on the padding of the container */
}


.pricing-container .plan:last-child button {
  background: #000; /* Set the background color to black */
  color: #fff; /* Set the text color to white for contrast */
}

/* Remove the margin-top from .plan button because it's now absolutely positioned */
.plan button {
  margin-top: 0;
}

.subscription-button {
  background: #0044cc;
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
}

.plan button:hover {
  background: #003399;
}

.best-value {
  border: 2px solid #0044cc;
}

.best-value .badge {
  position: absolute;
  top: -1rem;
  right: -1rem;
  background: #0044cc;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.75rem;
}


.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 16px;
  margin: 20px 0;
  padding-bottom: 20px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

.save-badge {
  background-color: #2196F3;
  border-radius: 10px;
  color: white;
  padding: 2px 8px;
  font-size: 14px;
}

.footer {
  background-color: #e9e3d4;
  color: #333;
  font-size: 14px;
  line-height: 1.5;
  padding: 20px 0;
  .container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
    padding: 0 15px;
  }
  .footer-about,
  .footer-links,
  .footer-contact {
    flex: 1;
    padding: 0 15px;
    h5 {
      margin-bottom: 0.5em;
    }
    p {
      margin-bottom: 0.5em;
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        margin-bottom: 0.25em;
      }
    }
  }
  .footer-bottom {
    text-align: center;
    margin-top: 20px;
    p {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 768px) {
  .footer .container {
    flex-direction: column;
    align-items: center;
  }
  .footer-about,
  .footer-links,
  .footer-contact {
    padding: 10px 0;
  }
  .footer-bottom p {
    font-size: 12px;
  }
}



/* Responsive adjustments */
@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.4rem;
    /* Smaller font size for hero title */
  }

  .hero p {
    font-size: 1rem;
    /* Smaller font size for hero description */
    margin-bottom: 4rem;
    /* Reduced margin */
  }

  .steps-container,
  .pricing-container {
    flex-direction: column;
    /* Stack elements vertically */
  }

  .step,
  .plan {
    margin: 0 1rem;
    /* Adjust margins */
    width: auto;
    /* Allow width to adjust to container */
  }

  .benefits-container {
    grid-template-columns: 1fr;
    /* Stack benefit cards vertically */
  }

  .benefit-card {
    margin: 0 1rem;
    /* Adjust margins */
  }

  .carousel-slide,
  .listing-card {
    flex-direction: column;
    /* Stack carousel elements vertically */
  }

  .listing-description,
  .listing-card {
    width: 100%;
    /* Full width for listing description and card */
  }
}

@media (max-width: 768px) {
  .step {
      margin-bottom: 2rem; /* Add space below each step */
  }

  /* Reset the bottom margin for the last step to maintain consistent spacing */
  .step:last-child {
      margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .pricing-container {
      justify-content: center; /* Center the flex items */
      flex-wrap: wrap; /* Allow items to wrap to the next line if space is insufficient */
  }

  .plan {
      width: 90%; /* Adjust the width to fit smaller screens */
      margin-bottom: 2rem; /* Add space below each plan */
  }

  .plan:last-child {
      margin-bottom: 0; /* Reset the bottom margin for the last plan */
  }
}

@media (max-width: 768px) {
  .example-listings {
    display: none;
  }
  .pricing {
    background-color: #e9e3d4;
  }
}
