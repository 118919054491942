
.form-header-container-pictures {
  display: flex;
  /* Use flexbox to position title and button */
  justify-content: space-between;
  /* Aligns children to opposite ends (start and end of the container) */
  align-items: center;
  /* Centers children vertically */
  margin-bottom: 0px;
  /* Space below the header container */
}

.image-preview-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.image-preview {
  position: relative;
  width: 100px;
  height: 100px;
}

.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  border-radius: 10px; /* Optional: for rounded corners */
}

.delete-image-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 0, 0, 0.7);
  color: white;
  border: none;
  cursor: pointer;
  padding: 2px 6px;
  border-radius: 0 5px 0 10px;
}

.file-upload-container {
  text-align: center;
  margin-top: 20px;
}

.file-input {
  display: none; /* Hide the default file input */
}

.drag-drop-container {
  border: 2px dashed #ccc;
  border-radius: 10px;
  text-align: center;
  padding: 40px 20px;
  margin-top: 20px;
  background-color: #fafafa;
  color: #8c8c8c;
  transition: border-color 0.3s;
  cursor: pointer;
  position: relative;
  margin-bottom: 20px;

  /* display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px 20px;
  background-color: #fafafa;
  color: #8c8c8c;
  transition: border-color 0.3s;
  cursor: pointer; */
  
}
/* 
.drag-drop-container p {
  margin: 0;
  font-size: 1rem;
} */

.drag-drop-container:hover,
.drag-drop-container.drag-over {
  border-color: #007bff;
  color: #007bff;
}

.file-upload-label {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  left: 50%;
  bottom: 10px; /* Adjust as needed */
  transform: translateX(-50%);
  transition: background-color 0.3s;

}

.file-upload-label:hover {
  background-color: #0056b3;
}

.max-photos-info {
  text-align: center;
  font-size: 0.9rem;
  margin-top: 10px;
  margin-bottom: 20px; /* Provide space between this text and the drag-drop container */
}


/* Add styles for the input that is being hidden */
#file-upload {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}


.submit-button {
  background-image: url('../images/checkmark.png');
  /* The path to your default button image */
  background-size: cover;
  /* Ensure the image covers the button */
  background-position: center;
  /* Center the image */
  border: none;
  /* Remove the border */
  width: 40px;
  /* Width of your image */
  height: 40px;
  /* Height of your image */
  cursor: pointer;
  /* Indicate the button is clickable */
  transition: background-image 0.3s ease;
  /* Smooth transition for image change */
  /* Other styling as necessary */
  background-color: transparent;
  transition: transform 0.3s ease, background-color 0.3s;

}

.submit-button:hover {
  transform: scale(1.1);
}