.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 1rem 1.5rem;
  background-color: rgba(244, 238, 224, 0.65); /* Semi-transparent background */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  backdrop-filter: blur(10px); /* Apply the blur effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
}
.logo {
  max-height: 45px;
}

li {
  vertical-align: middle;
}
.nav-links {
  list-style: none;
  display: flex;
  gap: 1.75rem;
  margin-bottom: 0px;
  align-items: center;
}

.nav-links a {
  text-decoration: none;
  color: #000;
  /* Add hover effects and other styles */
}

.nav-links a:hover {
  font-weight: bold;
}

/* Add this to your CSS file */
.btn-login {
  background-color: rgb(201, 196, 183);
  /* No background */
  
  /* White border */
  color: #fff;
  /* White text */
  padding: 12px 25px;
  /* Vertical and horizontal padding */
  border-radius: 10px;
  /* Rounded corners */
  font-weight: bold;
  /* Bold text */
  transition: all 0.3s ease;
  /* Smooth transition for hover effects */
  text-decoration: none;
  /* Remove underline from link */
  margin-right: 15px;
  /* Spacing to the right of the button */
}

.btn-login:hover {
  background-color: rgb(193, 188, 175);
  /* White background on hover */
  color: #000;
  /* Black text on hover */
}


.profile-icon {
  background-color: rgb(201, 196, 183);
  /* No background */
  height: 42px;
  width: 42px;
  /* White border */
  color: #fff;
  /* White text */
  padding: 12px 11px;
  /* Vertical and horizontal padding */
  border-radius: 21px;
  /* Rounded corners */
  font-weight: bold;
  /* Bold text */
  transition: all 0.3s ease;
  /* Smooth transition for hover effects */
  text-decoration: none;
  /* Remove underline from link */
  margin-right: 15px;
  /* Spacing to the right of the button */

  display: flex;
    align-items: center;
    justify-content: center;

}

.profile-icon:hover {
  background-color: rgb(193, 188, 175);
  /* White background on hover */
  color: #000;
  /* Black text on hover */
}

/* Existing styles */
.dropdown-menu {
  position: absolute;
  top: 60px; /* Adjust as needed to space below the dropdown button */
  right: 15px; /* Adjust as needed to space from the right side of the screen */
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Adds shadow for depth */
  padding: 0.5rem;
  margin-top: 0rem; /* Adds space between the toggle and the menu */
  display: flex;
  flex-direction: column;
  min-width: 160px; /* Ensures the menu has a minimum width */
}

.dropdown-menu a {
  padding: 0.5rem 1rem; /* Add more horizontal padding */
  text-decoration: none;
  color: #000;
  border-bottom: 1px solid #f2f2f2; /* Adds a separator between the links */
}

.dropdown-menu a:last-child {
  border-bottom: none; /* Removes the bottom border from the last link */
}

.dropdown-menu a:hover {
  background-color: #f2f2f2; /* Adds a background color on hover for visual feedback */
}

.profile-image {
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
  border-radius: 50%; /* Makes the image circular */
}


@media (max-width: 768px) {
  .header {
      display: flex;
      justify-content: space-between; /* Space out logo and button */
      align-items: center;
      flex-wrap: wrap; /* Allow elements to wrap */
  }

  .nav-links {
      width: 100%; /* Full width to occupy its own row */
      justify-content: flex-end; /* Align the button to the right */
      padding: 0; /* Adjust padding as needed */
  }

  .nav-links li {
      display: none; /* Hide all nav items */
  }

  .nav-links li:last-child {
      display: block; /* Only show the last item, which is the login button */
  }

  .logo {
      order: -1; /* Ensure logo is always first */
      flex-grow: 1; /* Allow the logo to take up available space */
      max-height: 32px; /* Adjust logo size as needed */
      margin-right: auto; /* Push everything else to the right */
  }
}
