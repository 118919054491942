/* Assuming your text area has the class 'input-field' applied to it */

  /* If you have a specific class for the text area, you can create a new rule */
  .textarea-field {
    width: 100%; /* Full width of the container */
    height: 20em; /* Provides more vertical space */
    padding: 15px; /* Padding inside the text area */
    border-radius: 10px; /* Rounded corners */
    border: 1px solid #ccc; /* Light border */
    font-size: 16px; /* Readable text size */
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    margin-bottom: 36px; /* Provides space between the text area and the button */
    /* Add any other styles such as margin or background-color as needed */
  }
