.dashboard-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
  position: relative;
}

.dashboard-title {
  margin: 0;
  margin-right: 0.5rem;
  /* Adjust this value to bring the button closer */
  text-align: center;
}

.create-listing-button {
  position: relative;
  z-index: 10;
  background-color: #007bff;
  /* Example blue color, replace with your color */
  border: none;
  border-radius: 50%;
  padding: 0.5rem;
  /* Adjust padding to scale the button size */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* Shadow for depth effect */
  cursor: pointer;
  transition: all 0.3s ease;
}

.create-listing-button img {
  display: block;
  width: 30px;
  /* Adjust size to fit the button */
  height: 30px;
  /* Adjust size to fit the button */
  color: white;
}

.create-listing-button:hover {
  transform: scale(1.17);
  /* Adjust scale to increase/decrease button size */
}


.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* Align content to the top */
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 140px;
  height: 100%;
  /* Ensure dashboard takes up full viewport height */
}

.dashboard h1 {
  /* margin-bottom: 2rem; */
  font-size: 2.2rem;
  text-align: center;
  margin-right: 1rem;
}

.dashboard h1 {
  margin-right: 1rem;
  /* Reduced margin to bring the button closer to the title */
}

.listings-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: 1rem auto;
}

.property-listing-card {
  position: relative;
  overflow: hidden; /* Changed from 'visible' to 'hidden' to contain the shadow effect */
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #e9e3d4;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* This will put space between your .card-content and .map-holder */
  min-height: 350px; /* Adjust the height as necessary */
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* Add margin-top if you want to push it down a bit, or remove it to center based on flexbox */
}

.map-holder {
  /* Assuming you want the map to take the remaining space after .card-content */
  flex-grow: 1; /* Allows the .map-holder to grow and fill the space */
  width: 100%; /* Map takes full width of card */
  display: flex; /* Enables flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  /* You may want to set a max-height or height here if the map is too tall */
  overflow: hidden; /* Ensures nothing spills out of the container */
  margin-top: 00px;
  margin-bottom: 20px;
}

.property-listing-card:hover {
  transform: translateY(-5px);
  overflow: visible;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.delete-button {
  position: absolute;
  top: 0;
  right: 0;
  background: red;
  /* Or the color you prefer */
  border: none;
  cursor: pointer;
  display: none;
  /* Hide by default, show on hover */
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  /* Circular shape */
  width: 30px;
  /* Size of the button */
  height: 30px;
  /* Size of the button */
  margin-right: -12px;
  margin-top: -12px;
  z-index: 10;
}

.delete-button img {
  width: 100%;
  height: auto;
}

.property-listing-card:hover .delete-button {
  overflow: visible;
  display: flex;
}

.listings-container.empty {
  display: flex;
  align-items: center;
  justify-content: center;
}


.no-listings {
  text-align: center;
  width: 100%;
  margin-top: -100px;
  /* Adjust as necessary */
}



.input-container2 {
  display: flex;
  align-items: center;
  border: 1px solid #CCCCCC;
  border-radius: 25px;
  margin-bottom: 24px; /* Adjust as necessary */
  background-color: white;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.input-label2 {
  background-color: transparent;
  border-right: 1px solid #CCCCCC;
  padding: 10px 15px;
  white-space: nowrap;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  font-size: 16px;
  width: 110px;
    text-align: left;
}

.input-field2 {
  flex: 1;
  padding: 15px 20px;
  border: none;
  border-radius: 0 25px 25px 0; /* Only right side rounded */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  outline: none;
  /* Ensure the input field takes up the remaining space */
}
.input-field2::placeholder {
  color: #CCCCCC;
}

.input-field2:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 4px rgba(128, 189, 255, 0.2);
}
.input-field2.invalid {
  border: 1px solid red; /* border color for invalid input */
}

.remaining-listings-tooltip {
  position: absolute;
  background-color: black;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}
