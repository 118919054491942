.form-step-container {
    max-width: 600px;
    width: 600px;
    background: #f4f4f4;
    padding: 30px;
    padding-bottom: 6px;
    border-radius: 15px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    margin: 40px auto;
    margin-top: 20px;
}

.input-container {
    display: flex;
    align-items: center;
    border: 1px solid #CCCCCC;
    border-radius: 25px;
    margin-bottom: 24px;
    background-color: white;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .input-label {
    background-color: transparent;
    border-right: 1px solid #CCCCCC;
    padding: 10px 15px;
    white-space: nowrap;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    font-size: 16px;
    width: 110px;
    text-align: left;
    font-weight: bold;
  }
  
  .input-field {
    flex: 1;
    padding: 15px 20px;
    border: none;
    border-radius: 0 25px 25px 0;
    font-size: 16px;
    outline: none;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    background-color: transparent;
  }
  .input-field::placeholder {
    color: #777777;
}

.input-field:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 4px rgba(128, 189, 255, 0.2);
}
  

.select-field {
  flex: 1;
  padding: 15px 20px;
  border: none;
  border-radius: 0 25px 25px 0;
  font-size: 16px;
  outline: none;
  -webkit-appearance: none; /* Remove default style on iOS */
  -moz-appearance: none;    /* Remove default style on Firefox */
  appearance: none;         /* Remove default style */
  background: url('data:image/svg+xml;utf8,<svg fill="gray" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat;
  background-position: right 15px top 50%;
  background-size: 12px;
  cursor: pointer;
}
  

.suggestions {
    list-style-type: none;
    padding: 0;
    margin-top: 2px; /* Space between input field and suggestions box */
    position: absolute;
    z-index: 1000;
    background-color: white;
    width: 540px;
    max-width: 540px;
    box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
    border-radius: 4px;
    overflow: hidden;
    text-align: left; /* Align text to the left */
}

.suggestions li {
    padding: 10px 20px; /* Match horizontal padding to input field's horizontal padding */
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    text-align: left; /* Align text to the left inside the suggestion */
}

/* If you have an icon or other element before the address text, adjust its styles as needed */
.suggestion-icon {
    margin-right: 10px; /* Space between the icon and the text */
    /* ... other styles ... */
}


.form-header-container {
    display: flex;
    /* Use flexbox to position title and button */
    justify-content: space-between;
    /* Aligns children to opposite ends (start and end of the container) */
    align-items: center;
    /* Centers children vertically */
    margin-bottom: 50px;
    /* Space below the header container */
}

.form-title {
    font-size: 26px;
    color: #333;
    margin: 0;
    /* Remove any default margins if present */
}

.next-button {
    background-image: url('../images/arrow_forward.png');
    /* The path to your default button image */
    background-size: cover;
    /* Ensure the image covers the button */
    background-position: center;
    /* Center the image */
    border: none;
    /* Remove the border */
    width: 40px;
    /* Width of your image */
    height: 40px;
    /* Height of your image */
    cursor: pointer;
    /* Indicate the button is clickable */
    transition: background-image 0.3s ease;
    /* Smooth transition for image change */
    /* Other styling as necessary */
    background-color: transparent;
}

.next-button:hover {
    background-image: url('../images/arrow_forward_filled.png');
    /* The path to your hover button image */
}

.back-button {
    background-image: url('../images/arrow_backward.png');
    /* The path to your default button image */
    background-size: cover;
    /* Ensure the image covers the button */
    background-position: center;
    /* Center the image */
    border: none;
    /* Remove the border */
    width: 40px;
    /* Width of your image */
    height: 40px;
    /* Height of your image */
    cursor: pointer;
    /* Indicate the button is clickable */
    transition: background-image 0.3s ease;
    /* Smooth transition for image change */
    /* Other styling as necessary */
    background-color: transparent;
}

.back-button:hover {
    background-image: url('../images/arrow_backward_filled.png');
    /* The path to your hover button image */
}

.spacer-button {
    /* Padding inside the button */
    border: 2px solid transparent;
    /* Remove the border */
    border-radius: 10px;
    /* Rounded corners for the button */
    background-color: transparent;
    /* Primary color for the button */
    color: transparent;
    /* Text color for the button */
    cursor: pointer;
    /* Change cursor to pointer */
    font-size: 16px;
    /* Font size for the button */
    line-height: 1;
    /* Adjust line height to control the height of the button */
    outline: none;
    /* Remove outline */
    transition: background-color 0.3s;
    /* Smooth transition for hover effect */
    cursor: default;
    width: 40px;
    /* Width of your image */
    height: 40px;
    /* Height of your image */
}

/* Add to PropertyAddress.css */

.suggestions {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    z-index: 1000;
    background-color: white;
    width: calc(100% - 20px); /* Adjust width as necessary */
    box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
    border-radius: 4px; /* Rounded corners for the dropdown */
    overflow: hidden; /* Ensures the child elements adhere to the border radius */
  }
  
  .suggestions li {
    padding: 10px 20px; /* Padding inside each suggestion */
    cursor: pointer;
    border-bottom: 1px solid #ddd; /* Border between suggestions */
    display: flex; /* Allows for icon and text alignment */
    align-items: center; /* Centers items vertically */
  }
  
  .suggestions li:last-child {
    border-bottom: none; /* Removes bottom border for the last item */
  }
  
  .suggestion-icon {
    margin-right: 10px; /* Space between icon and address text */
    color: #555; /* Icon color */
  }
  
  .suggestion-primary {
    font-weight: bold; /* Makes the main address text bold */
  }
  
  .suggestion-secondary {
    color: #888; /* Color for secondary address details */
    font-size: 0.9em; /* Smaller font size for secondary details */
  }
  
  .suggestions li:hover {
    background-color: #f2f2f2; /* Hover state background color */
  }
  

/* Responsive adjustments */
@media (max-width: 768px) {
    .form-container {
        padding: 20px;
        margin: 20px auto;
    }
}