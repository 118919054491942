.signup-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Aligns children to the start of the container */
    align-items: center; /* Centers children horizontally */
    height: calc(100vh - 80px);
    width: 100vw;
    margin-top: 60px;
    
  }
.signup-form-container {
    padding: 40px;
    border-radius: 8px;
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
    text-align: center;
    width: 400px;
    /* Adjust width as necessary */
    background-color: #f4ecdd;
    margin-bottom: 100px;
}

.signup-title {
    margin-bottom: 20px;
}

.google-signup-btn {
    background-color: #db4437;
    color: white;
    border: none;
    width: calc(100%);
    /* Input width minus padding */
    margin: 10px 0;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
    cursor: pointer;
    font-weight: bold;
    transition: transform 0.3s ease, background-color 0.3s;
}

.divider {
    margin: 30px 0;
    margin-top: 10px;
    color: #666;
}

.signup-input {
    display: block;
    width: calc(100%);
    /* Input width minus padding */
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.signup-btn {
    background-color: #212325;
    color: white;
    border: none;
    padding: 10px 20px;
    height: 40px;
    border-radius: 4px;
    width: 100%;
    cursor: pointer;
    font-weight: bolder;
    margin-top: 30px;
    margin-bottom: 40px;
    /* Add more styling as needed */
    transition: transform 0.3s ease, background-color 0.3s;
}

.signup-btn:hover {
    background-color: #0e0f10;
    transform: scale(1.05);
}
.google-signup-btn:hover {
    background-color: #c34236;
    transform: scale(1.05);
}
.login-link:hover {
    color: #026de0;
    transform: scale(1.05);
}

.login-link {
    display: block;
    margin-bottom: 10px;
    color: #007bff;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    transition: transform 0.3s ease, background-color 0.3s;
}

.login-linkHolder {
    text-decoration: none; /* Removes the underline from the link */
    color: #000; /* Set the color to your preference */
    /* Other styles as needed */
  }
  