
.settings {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align text to the start */
    justify-content: flex-start;
    padding: 5% 10%; /* Responsive padding */
    padding-top: 140px;
    max-width: 800px; /* Max width for readability */
    margin: auto; /* Center the container */
    color: #333; /* Dark text for contrast */
    font-family: 'Open Sans', sans-serif; /* Legible font */
}