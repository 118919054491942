/* TransactionType.css */

.form-header-container-transaction {
    display: flex;
    /* Use flexbox to position title and button */
    justify-content: space-between;
    /* Aligns children to opposite ends (start and end of the container) */
    align-items: center;
    /* Centers children vertically */
    margin-bottom: 50px;
    /* Space below the header container */
}

.radio-group {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    gap: 15px; /* Space between buttons */
    align-items: stretch; /* Stretch buttons to fill the container */
    margin-bottom: 30px;
  }
  
  .radio-label {
    padding: 20px; /* Increased padding for a taller button */
    font-size: 18px; /* Larger font size for better readability */
    border: 2px solid #000000; /* Blue border */
    border-radius: 20px; /* More pronounced rounded corners */
    background-color: transparent; /* Light grey background */
    color: #000; /* Black text color */
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition for the background color */
    text-align: center; /* Center text */
    font-weight: bold;
    transition: transform 0.3s ease, background-color 0.3s;
  }
  
  .radio-button:checked + .radio-label {
    background-color: #000000; /* Black background for selected button */
    color: white; /* White text for selected button */
  }

  .radio-label:hover {
    background-color: #353535; /* Black background for selected button */
    color: white; /* White text for selected button */
    transform: scale(1.05);

  }
  
  
  .radio-button {
    display: none; /* Hide the default radio button */
  }
  
  /* Add responsiveness if needed */
  @media (max-width: 768px) {
    .radio-group {
      flex-direction: column; /* Stack buttons vertically on smaller screens */
    }
  }